




























import accountModule from '@/store/modules/accountModule';
import workflowModule from '@/store/modules/workflowModule';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class AppSidebar extends Vue {
  expandedLink: any = {};

  userSettingsPageLink = {
    name: 'Settings',
    to: 'Settings',
  }

  links = [
    {
      name: 'Profile',
      to: 'UserProfile',
    },

    // {
    //   name: 'Issue',
    //   to: 'IssueStatus',
    // },

    //  {
    //   name: 'Groups',
    //   to: 'Groups',
    // },
    // {
    //   name: 'Activity',
    //   to: 'UserActivity',
    // },
    {
      name: 'Scheduler',
      to: 'PersonnelScheduler',
    },
    {
      name: 'Report',
      to: 'UserReport',
    },

  ];

  async created() {
     await workflowModule.getUserProfile({ username: accountModule.user.email.toLowerCase() });
    //  console.log(workflowModule.user)
    if (workflowModule.user.role === 'Engineers' || workflowModule.user.userAdminAccess ) {
      this.links.push({
        name: 'Admin',
        to: 'UserAdmin',
      });
    }
  }

  handleSidebarItemClick(link) {
    if (this.expandedLink && link.to === this.expandedLink.to) {
      this.$router.push({
        name: link.to,
      });
      return;
    }
    if (link.children?.length) {
      this.expandedLink = link;
      return;
    }
    this.$router.push({
      name: link.to,
    });
  }

  isActiveLink(link) {
    return link.to === this.$route.name
      || this.$route.meta.parent === link.name;
  }
}
